@font-face {
  font-family: redaction;
  src: url("../font/Redaction_50-Italic.woff2");
}

body {
  color: #fff;
  font-family: redaction, sans-serif;
  font-size: 20px;
  letter-spacing: 1px;
}

#bg {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

a {
  color: #fff;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  border-bottom: 2px solid #fff;
}

.blurb {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 300px;
}
